import axios from '@/helper/api.request';

const client = 'https://api.stormzhang.com'; // 测试
/**
 * 活动信息查询
 *
 * @auther hellohuan
 * @param id 活动id
 */
export const getActivity = id => {
  return axios.request({
    // url: '/api/activity/info/' + id,
    url: client + '/activity/lottery/activity?id=' + id + '&ts=' + Date.now(),
    method: 'get'
  });
};

/**
 * 奖品信息查询
 *
 * @auther hellohuan
 * @param id 奖品ID
 */
export const getPrize = id => {
  return axios.request({
    // url: '/api/results/info/' + id,
    url: client + '/activity/lottery/luckPrize?id=' + id + '&ts=' + Date.now(),
    method: 'get'
  });
};

/**
 * 抽奖用户查询（分页）
 *
 * @auther hellohuan
 * @param activityId 活动ID
 * @param pageNo 页码
 * @param pageSize 每页条数
 */
export const getJoinUser = ({ activityId, pageNo, pageSize, searchKey }) => {
  let temp = client + '/activity/lottery/user?activeNo=' + activityId;
  if (searchKey !== undefined) {
    temp += '&searchKey=' + searchKey;
  }
  return axios.request({
    url: temp + '&page=' + pageNo + '&limit=' + pageSize + '&ts=' + Date.now(),
    method: 'get'
  });
};

/**
 * 从抽奖名单中随机抽取 N 个用户
 *
 * @auther hellohuan
 * @param activityId 活动ID
 * @param
 */
export const getRandomUser = ({ activityId, randomSize }) => {
  return axios.request({
    url:
      client +
      '/activity/lottery/randomUser?activeNo=' +
      activityId +
      '&randomSize=' +
      randomSize +
      '&ts=' +
      Date.now(),
    method: 'get'
  });
};

/**
 * 中奖用户查询（分页）
 *
 * @auther hellohuan
 * @param activityId 活动ID
 * @param prizeId 奖品ID
 * @param pageNo 页码
 * @param pageSize 每页条数
 */
export const getLuckUser = ({
  activityId,
  luckCode,
  searchKey,
  pageNo,
  pageSize
}) => {
  let temp = client + '/activity/lottery/luckUser?activeNo=' + activityId;
  if (luckCode !== undefined) {
    temp += '&luckCode=' + luckCode;
  }
  if (searchKey !== undefined) {
    temp += '&searchKey=' + searchKey;
  }
  return axios.request({
    url: temp + '&page=' + pageNo + '&limit=' + pageSize + '&ts=' + Date.now(),
    method: 'get'
  });
};

/**
 * 获取用户头像地址
 *
 * @auther hellohuan
 * @param id 用户id
 */
export const getImageUrl = id => {
  return axios.request({
    // url: '/api/results/info/' + id,
    url:
      'https://h.hukp.cn:155/zsxq/user/avatar?id=' + id + '&ts=' + Date.now(),
    method: 'get'
  });
};

/**
 * 最新活动列表
 * @param pageNo 页码
 * @param pageSize 每页条数
 * @auther ztt
 */
export const getActivityList = ({ pageNo, pageSize }) => {
  return axios.request({
    url:
      client +
      '/activity/lottery/list?page=' +
      pageNo +
      '&limit=' +
      pageSize +
      '&ts=' +
      Date.now(),
    method: 'get'
  });
};
