import axios from 'axios';
import { getToken, setToken } from '@/helper/util';

class HttpRequest {
  constructor(baseUrl = window.baseURL) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }

  getInsideConfig() {
    var token = getToken();
    const config = {
      baseURL: this.baseUrl,
      headers: {
        Authorization: token || ''
      },
      timeout: 30000
    };
    return config;
  }

  distroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    instance.interceptors.request.use(
      config => {
        // 添加全局的loading...
        // if (!Object.keys(this.queue).length) {
        // }
        this.queue[url] = true;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        this.distroy(url);
        const {
          data
          // 请求拦截
        } = res;
        if (res.data && res.data.code === 401) {
          setToken('');
          window.location.reload();
        }

        return {
          data,
          status
        };
      },
      error => {
        this.distroy(url);
        if (error.message.includes('timeout')) {
          // 判断请求异常信息中是否含有超时timeout字符串
          error['timeout'] = true;
        } else {
          error['timeout'] = false;
        }
        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    // options = Object.assign(this.getInsideConfig(), options)

    this.interceptors(instance, options.url);
    return instance(options);
  }
}

export default HttpRequest;
