import HttpRequest from '@/helper/axios';

// linx 设置全局api服务器地址
const baseUrl = process.env.HOST;
// 如果没有设置，则默认取当前域名作为服务器地址
if (!baseUrl) {
  window.baseURL = location.protocol + '//' + location.hostname;
  if (location.port) {
    window.baseURL = window.baseURL + ':' + location.port;
  }
  window.baseURL = window.baseURL + '/api/';
} else {
  window.baseURL = baseUrl;
}
const axios = new HttpRequest(window.baseURL);
export default axios;
